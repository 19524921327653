import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required"

const profile = {
  EDIT_PROFILE: "edit",
}
const addresses = {
  EDIT_ADDRESS: "edit-address",
  ADD_ADDRESS: "add-address",
}

export const formInitialValues = (module, userData, address = "") => {
  switch (module) {
    case profile.EDIT_PROFILE:
      return {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        mobileNumber: userData?.mobileNumber?.replace("+63", ""),
        birthday: {
          month: {
            value: userData?.birthday?.month || "",
            label: userData?.birthday?.month || "",
          },
          date: {
            value: userData?.birthday?.date || "",
            label: userData?.birthday?.date || "",
          },
          year: {
            value: userData?.birthday?.year || "",
            label: userData?.birthday?.year || "",
          },
        },
        userData: userData,
      }

    case addresses.EDIT_ADDRESS:
    case addresses.ADD_ADDRESS:
      return {
        index: address?.index,
        address: {
          addressType:
            address?.type?.replace(/^\w/, (c) => c.toUpperCase()) || "",
          streetAddress: address?.streetAddress || "",
          primary: address?.primary || false,
          province: address?.province
            ? {
                value: address?.province || "",
                label: address?.province || "",
              }
            : null,
          city: address?.city
            ? {
                value: address?.city || "",
                label: address?.city || "",
              }
            : null,
          barangay: address?.barangay
            ? {
                value: address?.barangay || "",
                label: address?.barangay || "",
              }
            : null,
        },
      }
    default:
      return {}
  }
}

export const profileFormSchema = (module) => {
  switch (module) {
    case profile.EDIT_PROFILE:
      return editProfileSchema

    case addresses.ADD_ADDRESS:
    case addresses.EDIT_ADDRESS:
      return addressValidationSchema
    default:
      return null
  }
}

const editProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test('is-not-only-spaces', 'No name inputted', value => value.trim() !== ''),
  lastName: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test('is-not-only-spaces', 'No name inputted', value => value.trim() !== ''),
  email: Yup.string().required(REQUIRED_MESSAGE),
})

const addressValidationSchema = Yup.object().shape({
  address: Yup.object().shape({
    addressType: Yup.string().required(REQUIRED_MESSAGE),
    streetAddress: Yup.string().required(REQUIRED_MESSAGE),
    city: Yup.object()
      .shape({
        label: Yup.string().required(REQUIRED_MESSAGE),
        value: Yup.string().required(REQUIRED_MESSAGE),
      })
      .nullable()
      .required(REQUIRED_MESSAGE),
    province: Yup.object().shape({
      label: Yup.string().required(REQUIRED_MESSAGE),
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }),
})
