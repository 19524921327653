import React, { useContext, useState } from "react"
import classNames from "classnames"

import Message from "elements/Message"

import { AppContext } from "../../../context/AppContext"
import { deleteAddress } from "../services/addresses"

const DeleteAddressModal = ({ address, index, handleDeleteCallback }) => {
  const [loading, setLoading] = useState(false)
  const { dispatch } = useContext(AppContext)

  const handleCloseModal = () => {
    dispatch({ type: "HIDE_MODAL" })
  }

  const handleDeleteAddress = () => {
    setLoading(true)
    deleteAddress({
      index,
      callback: (addressDocument) => {
        setLoading(false)
        if (handleDeleteCallback) handleDeleteCallback(addressDocument)
        dispatch({ type: "HIDE_MODAL" })
        dispatch({
          type: "SHOW_TOAST",
          payload: {
            message: `Address deleted.`,
            color: "success",
          },
        })
      },
    })
  }

  return (
    <div className="has-text-left">
      <p className="is-size-6">
        Address Type:{" "}
        <span className="has-text-weight-bold is-capitalized">
          {address.type} {address.primary && "(Default)"}
        </span>
      </p>
      <p className="mb-1 is-size-6">
        {address.street || address.streetAddress}, {address.city},{" "}
        {address.province} {address.notes ? `(${address.notes})` : ""}
      </p>
      <Message color="danger mt-1">
        Are you sure you want to delete this address? <br />
        <b>This action can't be undone.</b>
      </Message>
      <div className="buttons is-centered mt-2">
        <button
          className="button is-primary is-outlined"
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button
          className={classNames("button is-danger", {
            "is-loading": loading,
          })}
          onClick={handleDeleteAddress}
        >
          Delete
        </button>
      </div>
    </div>
  )
}

export default DeleteAddressModal
